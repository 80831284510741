<template>
  <v-col
    cols="12"
    sm="12"
  >
    <v-toolbar
      flat
      color="accent"
    >
      <v-spacer />
      <export-excel :section="22"/>
    </v-toolbar>

    <v-tabs
      v-model="tab"
      grow
      show-arrows="mobile"
      background-color="transparent"
    >
      <v-tab>Project Participants</v-tab>
      <v-tab v-if="usersStore.authUserType !== 3">
        Organizations
      </v-tab>
      <v-tab v-if="usersStore.authUserType !== 3">
        Collaborators
      </v-tab>

      <!--RPPR Particiapnts-->
      <v-tab-item>
        <v-card
          flat
          color="accent"
        >
          <v-row dense>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="text-center"
            >
              <span class="text-h8 nsfgrey--text">Individuals on the list below are reported in the DOP as being under your supervision.  To enter their participation info for RPPR reporting, click in the blank box that corresponds to the year you are reporting.  If the box is already checked,  information has already been added and clicking on the checked box allows you to review/edit. </span>
            </v-col>
          </v-row>
          <users-rppr
            :key="usersStore.activeUser.id + usersStore.activeProject.id"
            :section="usersStore.activeUserType === 3 ? 12 : usersStore.activeUserType === 2 ? 10 : 2"
          />
        </v-card>
      </v-tab-item>

      <!--Organizations-->
      <v-tab-item v-if="usersStore.authUserType !== 3">
        <v-card
          flat
          color="accent"
        >
          <v-row dense>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="text-center"
            >
              <span class="text-h8 nsfgrey--text">In this section, list organizations that you've been involved in partnering with on this T-2 project. Fields that are non-required for DOP purposes are required for PI's annual RPPR thus completion is recommended. </span>
            </v-col>
          </v-row>
          <rppr-organizations
            :section="1"
            :readonly="usersStore.authUserType === 4 || usersStore.authUserType === 7"
          />
        </v-card>
      </v-tab-item>

      <!--Collaborators-->
      <v-tab-item v-if="usersStore.authUserType !== 3">
        <v-card
          flat
          color="accent"
        >
          <v-row dense>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="text-center"
            >
              <span class="text-h8 nsfgrey--text">In this section, list collaborators that you've worked with on this T-2 project that you haven't listed under Participants or Organizations (it is likely that you won't have any). Fields that are non-required for DOP purposes are required for PI's annual RPPR thus completion is recommended if you have data to enter. </span>
            </v-col>
          </v-row>
          <rppr-collaborators
            :section="1"
            :readonly="usersStore.authUserType === 4 || usersStore.authUserType === 7"
          />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import UsersRppr from '@/components/UsersRppr';
import RpprOrganizations from '@/components/RpprOrganizations';
import RpprCollaborators from '@/components/RpprCollaborators';
import ExportExcel from '@/components/ExportExcel'

export default {
  name: 'RPPRList',
  components: {
    UsersRppr,
    RpprOrganizations,
    RpprCollaborators,
    ExportExcel,
  },
  mixins: [mixin],
  data() {
	  return {
	  	tab:0
	  };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      
    }),
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {

    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
