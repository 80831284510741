<template>
  <div>
    <v-tooltip
      v-if="usersStore.authUserType !== 3 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add RPPR Collaborator</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="800"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        max-height="740"
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          RPPR Collaborator Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <span class="subtitle-1 font-weight-bold black--text">In this section, list collaborators that you've worked with on this T-2 project that you haven't listed under Participants or Organizations (it is likely that you won't have any).  Fields that are non-required for DOP purposes are required for PI's annual RPPR thus completion is recommended if you have data to enter.</span>
                </v-col>
              </v-row>
              <v-row dense>
                <!--Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-textarea
                    v-model="name"
                    label="List Any Other People Or Organizations Involved In The Project."
                    placeholder=" "
                    rows="2"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Years-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <span class="grey--text text--darken-2 text-caption font-weight-medium pb-0">Collaboration Years</span>
                  <v-radio-group
                    column
                    class="my-0"
                  >
                    <v-checkbox
                      v-for="(item,index) in valueListsStore.rpprYear"
                      :key="index"
                      v-model="years"
                      dense
                      :label="item.value"
                      multiple
                      color="primary"
                      :value="item.value"
                      class="pr-2 py-0 mt-0"
                      :readonly="readonly"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row
                v-if="mode !== 1"
                dense
              >
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="flag_status === 0 ? [] : rules.flag_status"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'rpprCollaboratorsStore/getEntryField',
  mutationType: 'rpprCollaboratorsStore/updateEntryField',
});

export default {
  name: 'RPPRCollaborator',
  mixins: [mixin],
  props: {
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        name: [
			    v => !!v || 'Required',
        ],
        flag_status: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      rpprCollaboratorsStore: state => state.rpprCollaboratorsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'id_project',
      'name',
      'years',
      'flag_status'
    ]),
  },
  methods: {

    async newEntry() {
      this.$store.dispatch('rpprCollaboratorsStore/resetEntry');
      const activeProject = this.$store.getters['usersStore/getActiveProject'];
      this.id_project = activeProject.id;
      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['rpprCollaboratorsStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('rpprCollaboratorsStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('rpprCollaboratorsStore/update', entry);
        }
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
