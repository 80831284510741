<template>
  <div>
    <v-tooltip v-if="section !== 12 && section !== 2" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            :color="
              section === 9 && $vuetify.theme.dark === false
                ? 'white'
                : 'secondary'
            "
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add RPPR User</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="900"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card max-height="840" flat>
        <v-card-title class="text-h5 secondary--text">
          User Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>

        <v-card-text v-if="emailCheck !== 1">
          <v-form
            ref="searchForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="searchByEmail()"
          >
            <v-container fluid>
              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="6">
                  <v-row dense>
                    <!--Left Side-->
                    <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="emailAddress"
                        label="Email"
                        placeholder=" "
                        required
                        :rules="rules.email"
                      />
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-btn
                        block
                        rounded
                        color="primary"
                        @click="searchByEmail()"
                      >
                        <v-icon small left> fal fa-check </v-icon>Search
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-text v-if="emailCheck === 1">
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row v-if="!id" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 error--text"
                    >Adding a new user to the system.</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id_participation" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text">
                    Then you may proceed to add them to the project with their
                    new role or their new title.</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >User already exists in the system. Add participation for
                    the user.</span
                  >
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="6">
                  <v-row dense>
                    <!--Email-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="email"
                        label="Email"
                        placeholder=" "
                        required
                        :rules="rules.email"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="name_first"
                        label="First Name"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="name_last"
                        label="Last Name"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="6" class="pl-4">
                  <v-row v-if="!id_participation && mode === 1" dense>
                    <!--Start Month-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_month"
                        :items="valueListsStore.monthAbbreviations"
                        item-text="value"
                        item-value="value"
                        label="Participation Start Month"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>

                    <!--Start Year-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_year"
                        :items="valueListsStore.projectYearList"
                        item-text="value"
                        item-value="value"
                        label="Participation Start Year"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <!--Researchers List For Trainees-->
                  <v-row v-if="showParticipantsSelector" dense>
                    <v-col cols="12" sm="12">
                      <participants-selector
                        :user-type="1"
                        :section="6"
                        :role-selector="0"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="emailCheck === 1" class="mx-4 pb-4">
          <v-btn block rounded color="primary" @click="saveEntry()">
            <v-icon small left> fal fa-check </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields } = createHelpers({
  getterType: "rpprStore/getUserEntryField",
  mutationType: "rpprStore/updateUserEntryField",
});

export default {
  name: "UserRppr",
  components: {
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    /*
     1 = Settings Users List,
     2 = RPPR List,
     3 = Project Detail Users List,
     4 = Trainees List (Researcher List Of Trainees),
     5 = Admin Researchers List,
     6 = Admin Trainees List,
     7 = Admin Others Project Users List,
     8 = Admin RPPR List,
     9 = Trainees Dashboard,
     10 = RPPR Researchers List,
     11 = User Detail
     12 = RPPR Trainees List
     */
    /*1 = User Settings, 2 = User Detail, 3 = */
    section: Number,
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        not_empty: [(v) => !!v || ""],
        email: [(v) => !!v || ""],
      },
    };
  },
  computed: {
    ...mapState({
      rpprStore: (state) => state.rpprStore,
      usersStore: (state) => state.usersStore,
      valueListsStore: (state) => state.valueListsStore,
      projectsStore: (state) => state.projectsStore,
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "sectionStored",

      "name_first",
      "name_last",
      "email",
      "emailAddress",
      "password",
      "passwordConfirmation",
      "flag_status",
      "flag_admin",
      "flag_hide",
      "flag_show",
      "emailCheck",

      "id_participation",
      "id_project",
      "date_start_project",
      "date_start_month",
      "date_start_year",
      "flag_user_type",
      "advisors",
      "flag_email",
    ]),
    showParticipantsSelector() {
      return !this.id_participation && this.mode === 1;
    },
  },
  methods: {
    async newEntry() {
      let newSection = this.section === 10 ? 101 : this.section;
      await this.$_loadValueLists(Number(5 + "." + newSection));

      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const activeUserType = this.$store.getters[
        "usersStore/getActiveUserType"
      ];
      this.$store.dispatch("rpprStore/resetUserEntry");
      this.$store.dispatch("participantsStore/clearResearchers");
      this.$store.dispatch("participantsStore/clearParticipantsSelected");
      let project = this.$store.getters["usersStore/getActiveProject"];
      this.id_project = project.id;
      this.date_start_project = project.date_start;
      this.flag_user_type = 8;

      /*Participant Selector*/
      const researcherData = {
        id_project: this.id_project,
        type: 1,
      };
      await this.$store.dispatch("participantsStore/fetch", researcherData);
      if (activeUserType === 1 || activeUserType === 2) {
        const data = {
          id: activeUser.id,
          role: "",
          name_reverse: activeUser.name_reverse,
          type: 1,
        };
        await this.$store.dispatch("participantsStore/addParticipant", data);
      }

      this.show = true;

      if (this.$refs.searchForm) {
        this.$refs.searchForm.resetValidation();
      }
    },

    async openEntry(id) {
      let project = this.$store.getters["usersStore/getActiveProject"];
      await this.$_loadValueLists(Number(5 + "." + this.section));
      this.$store.dispatch("participantsStore/clearParticipantsSelected");
      let advisors = this.$store.getters["usersStore/getAdvisors"];

      let newAdvisors = [];
      for (var i = 0; i < advisors.length; i++) {
        if (advisors[i].pivot.id_project === project.id) {
          const idx = newAdvisors
            .map((item) => item.id)
            .indexOf(advisors[i].id);
          if (idx < 0) {
            newAdvisors.push(advisors[i]);
          }
        }
      }

      await this.$store.dispatch(
        "participantsStore/setParticipants",
        newAdvisors
      );
      await this.$store.dispatch("usersStore/entry", id);
    },

    cancelEntry() {
      this.show = false;
    },

    async searchByEmail() {
      if (this.$refs.searchForm.validate()) {
        const entry = this.$store.getters["rpprStore/getUserEntry"];

        if (!this.emailCheck) {
          await this.$store.dispatch("rpprStore/readByEmail", entry);
        }
      }
    },

    async saveEntry() {
      if (
        this.$_projectDateStart(
          this.date_start_project,
          this.date_start_month,
          this.date_start_year
        )
      ) {
        const supervisorsSelected =
          this.$store.getters["participantsStore/getParticipantsSelected"]
            .length > 0;

        if (
          (this.$refs.entryForm.validate() && !this.showParticipantsSelector) ||
          (this.$refs.entryForm.validate() &&
            this.showParticipantsSelector &&
            supervisorsSelected)
        ) {
          this.sectionStored = this.section;
          this.advisors = this.$store.getters[
            "participantsStore/getParticipantsSelected"
          ];

          const entry = this.$store.getters["rpprStore/getUserEntry"];

          /* Create */
          if (this.mode === 1) {
            await this.$store.dispatch("rpprStore/createUser", entry);
          } else {
            /* Edit */
            //await this.$store.dispatch('rpprStore/updateUser', entry);
          }
          this.cancelEntry();
        }
      } else {
        /* Show error notification */
        let data = {
          color: "error",
          text:
            "Start date must be later than project start of " +
            this.$_formatDate(this.date_start_project),
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },

    async pullResearchers() {
      if (this.id_project && this.flag_user_type) {
        const researcherData = {
          id_project: this.id_project,
          type: 1,
        };
        await this.$store.dispatch("participantsStore/fetch", researcherData);
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
