import { render, staticRenderFns } from "./RpprCollaborator.vue?vue&type=template&id=2b3c0c3e&scoped=true&"
import script from "./RpprCollaborator.vue?vue&type=script&lang=js&"
export * from "./RpprCollaborator.vue?vue&type=script&lang=js&"
import style0 from "./RpprCollaborator.vue?vue&type=style&index=0&id=2b3c0c3e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b3c0c3e",
  null
  
)

export default component.exports