<template>
  <div>
    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="800"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          RPPR Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <!--Active Participation Project Role-->
                <v-col
                  cols="12"
                  sm="12"
                />
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col
                  cols="12"
                  sm="12"
                  md="7"
                >
                  <v-row dense>
                    <span class="primary--text">Project Participation</span>
                  </v-row>
                  <template v-for="(item, index) in participation">
                    <v-row
                      :key="index"
                      dense
                    >
                      <!--Start Month-->
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                      >
                        <text-display
                          :display-string="item.date_start_month"
                          :label="'Start Month'"
                        />
                      </v-col>

                      <!--Start Year-->
                      <v-col
                        cols="12"
                        sm="12"
                        md="2"
                      >
                        <text-display
                          :display-number="item.date_start_year"
                          :label="'Start Year'"
                        />
                      </v-col>
                      
                      <!--Role-->
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                      >
                        <v-autocomplete
                          v-model="item.role"
                          :items="valueListsStore.classificationsRppr"
                          label="Role On Project"
                          placeholder=" "
                          item-text="value"
                          item-value="value"
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--End Month-->
                      <v-col
                        v-if="item.flag_status !== 1"
                        cols="12"
                        sm="12"
                        md="2"
                      >
                        <text-display
                          :display-string="item.date_end_month"
                          :label="'End Month'"
                        />
                      </v-col>

                      <!--End Year-->
                      <v-col
                        v-if="item.flag_status !== 1"
                        cols="12"
                        sm="12"
                        md="2"
                      >
                        <text-display
                          :display-number="item.date_end_year"
                          :label="'End Year'"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    > 
                      <span
                        class="red--text pt-6"
                      >
                        Fill out the Role on Project for each participation period shown above.  Then, if the participant had any project activity during this reporting year based on the participation periods shown,  the information below should be completed for the year checkbox that you selected in the previous screen.  If the participant had NO ACTIVITY during this reporting year based on the project participation shown above, you can skip the section below and simply hit SUBMIT.
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider 
                    class="pb-4"
                  />
                    
                  <v-row dense>
                    <!--Year of Schooling Completed-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="year_schooling_completed"
                        :items="valueListsStore.rpprSchooling"
                        item-text="value"
                        item-value="value"
                        label="Year of Schooling Completed (Only For Research Experience for Undergraduates)"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>
                    <!--Home Institution-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="home_institution"
                        label="Home Institution (Only For Research Experience for Undergraduates)"
                        placeholder=" "
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Appointment Term-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                    >
                      <v-autocomplete
                        v-model="appointment_term"
                        :items="valueListsStore.rpprAppointmentTerm"
                        item-text="value"
                        item-value="value"
                        label="Appointment Term"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                        @input="personMonths(appointment_term,appointment_term_other,percent_effort)"
                      />
                    </v-col>
                    <!--Appointmet Term Other-->
                    <v-col
                      v-if="appointment_term === 'Other'"
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="appointment_term_other"
                        label="Other Appointment Term In Months (Enter number only Ex: 18)"
                        placeholder=" "
                        :readonly="readonly"
                        @input="personMonths(appointment_term,appointment_term_other,percent_effort)"
                      />
                    </v-col>
                    <!--Percent Effort-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="percent_effort"
                        label="Percent Effort (in decimals Ex: .25)"
                        placeholder=" "
                        :readonly="readonly"
                        @input="personMonths(appointment_term,appointment_term_other,percent_effort)"
                      />
                    </v-col>
                    <!--Person Months-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="person_months"
                        label="Person Months"
                        placeholder=" "
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Funding Support-->
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-text-field
                        v-model="funding_support"
                        label="Funding Support"
                        placeholder=" "
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Contribution To Project-->
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-textarea
                        v-model="contribution"
                        label="Contribution To Project"
                        placeholder=" "
                        rows="2"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Is this person collaborating internationally on this project?-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="collab_internationally"
                        :items="valueListsStore.yesNo"
                        label="Is this person collaborating internationally on this project?"
                        item-text="value"
                        item-value="value"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="collab_internationally === 'Yes' && readonly !== true"
                    dense
                  >
                    <!--Country(ies) of foreign collaborator-->
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-select
                        v-model="collab_countries"
                        :items="countriesStore.countriesValueList"
                        label="Country(ies) of foreign collaborator"
                        placeholder=" "
                        item-text="name"
                        item-value="name"
                        :clearable="readonly !== true"
                        multiple
                        chips
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Spacer Column-->
                <v-col
                  cols="12"
                  sm="12"
                  md="1"
                />

                <!--Right Side-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <span class="grey--text text--darken-2 text-caption font-weight-medium pb-0">Government Fiscal Year REU participant supported  (Only For Research Experience for Undergraduates)</span>
                  <v-radio-group
                    column
                    class="my-0"
                  >
                    <v-checkbox
                      v-for="(item,index) in valueListsStore.projectYearList"
                      :key="index"
                      v-model="gov_fiscal_year"
                      dense
                      :label="item.value.toString()"
                      multiple
                      color="primary"
                      :value="item.value.toString()"
                      class="pr-2 py-0 mt-0"
                      :readonly="readonly"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <!--Traveled internationally for this award-->
                  <v-autocomplete
                    v-model="travel_internationally"
                    :items="valueListsStore.yesNo"
                    label="Traveled internationally for this award"
                    item-text="value"
                    item-value="value"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="travel_internationally === 'Yes' && readonly !== true"
                dense
              >
                <v-spacer />
                <v-btn
                  rounded
                  color="primary"
                  @click="addItem()"
                >
                  <v-icon
                    small
                    left
                  >
                    fal fa-plus
                  </v-icon>Add Country
                </v-btn>
                <v-col
                  cols="12"
                  sm="12"
                >
                  List countries traveled internationally or this award
                </v-col>
              </v-row>

              <template v-if="travel_internationally === 'Yes'">
                <v-card
                  v-for="(item, index) in travel_countries"
                  :key="index"
                  flat
                >
                  <v-row dense>
                    <!--Country-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="item.country"
                        :items="countriesStore.countriesValueList"
                        label="Country"
                        item-text="name"
                        item-value="name"
                        placeholder=" "
                        :clearable="readonly !== true"
                        clear-icon="fal fa-times-circle"
                        :readonly="readonly"
                      />
                    </v-col>

                    <!--Years-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="item.years"
                        :items="valueListsStore.yearsNum"
                        label="Years"
                        placeholder=" "
                        :clearable="readonly !== true"
                        :readonly="readonly"
                      />
                    </v-col>

                    <!--Months-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="item.months"
                        :items="valueListsStore.monthsNum"
                        item-text="id"
                        item-value="id"
                        label="Months"
                        placeholder=" "
                        :clearable="readonly !== true"
                        :readonly="readonly"
                      />
                    </v-col>

                    <!--Days-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                    >
                      <v-autocomplete
                        v-model="item.days"
                        :items="valueListsStore.days"
                        label="Days"
                        placeholder=" "
                        :clearable="readonly !== true"
                        :readonly="readonly"
                      />
                    </v-col>

                    <!--Remove-->
                    <v-col
                      cols="12"
                      sm="12"
                      md="1"
                    />
                  </v-row> 
                </v-card>
              </template>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: 'rpprStore/getEntryField',
  mutationType: 'rpprStore/updateEntryField',
});

export default {
  name: 'Rppr',
  components: {
    TextDisplay,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      rpprStore: state => state.rpprStore,
      usersStore: state => state.usersStore,
      valueListsStore: state => state.valueListsStore,
      countriesStore: state => state.countriesStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'index',

      'id_project',
      'id_user',
      'id_year',
      'year_schooling_completed',
      'home_institution',
      'gov_fiscal_year',
      'percent_effort',
      'appointment_term',
      'appointment_term_other',
      'funding_support',
      'person_months',
      'contribution',
      'collab_internationally',
      'collab_countries',
      'travel_internationally',
      'flag_status'
    ]),
    ...mapMultiRowFields('rpprStore', ['entry.travel_countries','entry.participation']),
  },
  props: {
    readonly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        name: [
			    v => !!v || 'Required',
        ],
        flag_status: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  methods: {

    cancelEntry() {
      this.show = false;
    },

    personMonths(term,otherTerm,effort) {
      if(term && effort || otherTerm && effort) {
        if(otherTerm) {
          this.person_months = Number(otherTerm) * Number(effort)
        }
        else
        {
          let termNumber
          if(term === '12 Months') {
            termNumber = 12
          }
          else if(term === '9 Months') {
            termNumber = 9
          }
          else if(term === '3 Months') {
            termNumber = 3
          }
          else {
            termNumber = 0
          }
          this.person_months =  Number(termNumber) * Number(effort)
        }
      }
      else {
        this.person_months = ''
      }
    },

    addItem() {
      this.$store.dispatch('rpprStore/addItem');
    },

    removeItem(id) {
      this.$store.dispatch('rpprStore/removeItem', id);
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['rpprStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('rpprStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('rpprStore/update', entry);
        }
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>